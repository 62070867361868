import { useContext } from "react";
import styled from "styled-components";

import GuideLayout from "../../components/GuideLayout";
import VoicePingUpdate from "../../images/vp_search.png";
import VoicePingUpdated from "../../images/vp_update.png";
import VoicePingApp from "../../images/voiceping_app.png";

import { GuideContext } from "../../pages/guide";
import { goNext, goBack } from "../../reducers/guide";

import * as s from "./StyledComponents";

const VIEW_TITLE = "Update VoicePing app";

const Image = styled.img`
  border-radius: 12px;
  border: 2px solid grey;
  padding: 4px;
  margin-bottom: 24px;
  width: 80%;
`;

function StepView({ id }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickNext = () => goNext(dispatch);
  const handleClickBack = () => goBack(dispatch);

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <div>
        Please make sure you have the <strong>latest version</strong> of
        VoicePing app on your device. You can download or update VoicePing app
        from Play Store. Open Play Store app, then search for “voiceping”.
      </div>
      <div>You will find this app:</div>
      <Image src={VoicePingApp} alt="voiceping-app-play-store-listing" />
      <div>
        Press on the VoicePing app icon then press the Update button. If there
        is no Update button, this means VoicePing app is already updated to the
        latest version.
      </div>
      <Image src={VoicePingUpdate} alt="voiceping-app-not-updated" />
      <div>
        If you pressed on <strong>Update</strong> button, wait until the
        updating process finishes. Then press <strong>Open</strong> button to
        launch VoicePing app.
      </div>
      <Image src={VoicePingUpdated} alt="voiceping-app-updated" />
      <s.CheckPoints>
        <div>
          <input type="checkbox" id="email-check" />
          <label htmlFor="email-check">
            I have updated the App to the latest version
          </label>
        </div>
      </s.CheckPoints>
    </GuideLayout>
  );
}

export default StepView;
