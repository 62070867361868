import styled from "styled-components";

export const ButtonLink = styled.span`
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
`;

export const Image = styled.img`
  border-radius: 12px;
  border: 2px solid grey;
  padding: 4px;
  margin-bottom: 24px;
  width: 100%;
`;

export const CheckPoints = styled.div`
  > div {
    display: flex;
    align-items: baseline;

    > label {
      flex: 1;
      margin-left: 8px;
    }
  }
`;
