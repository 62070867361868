import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

import "antd/dist/antd.css";

import IndexPage from './pages/index'
import NotFoundPage from './pages/404'
import SuccessPage from './pages/success'
import GuidePage from './pages/guide'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='success' exact element={<SuccessPage />} />
        <Route path='guide' exact element={<GuidePage />} />
        <Route path='/' exact element={<IndexPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
