import styled from "styled-components";

export const Image = styled.img`
  border-radius: 12px;
  border: 2px solid grey;
  padding: 4px;
  margin-bottom: 24px;
  width: 70%;
`;

export const CheckPoints = styled.div`
  > div {
    display: flex;
    align-items: baseline;

    > label {
      flex: 1;
      margin-left: 8px;
    }
  }
`;
