import { useContext } from "react";
import axios from "axios";
import GuideLayout from "../../components/GuideLayout";

import EmailCredentialImg from "../../images/email_credential.png";

import { GuideContext } from "../../pages/guide";
import { goNext, goBack } from "../../reducers/guide";

import * as s from "./StyledComponents";

const VIEW_TITLE = "Check email login information";
const serverUrl = process.env.REACT_APP_SERVER_URL;

function StepView({ id }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickNext = () => goNext(dispatch);
  const handleClickBack = () => goBack(dispatch);

  function handleResendEmail() {
    if (!window.vp_dla_uname || !window.vp_dla_pass) {
      return;
    }

    const body = {
      email: state.email,
      username: window.vp_dla_uname,
      password: window.vp_dla_pass,
    };

    axios.post(serverUrl + "/resend-email", body);
  }

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <div>
        If activation is successful, you should get the login credentials in
        your email <strong>Inbox</strong>.{" "}
        {window.vp_dla_uname && window.vp_dla_pass && (
          <>
            If it's not there, please{" "}
            <s.ButtonLink onClick={handleResendEmail}>
              click here to re-send the email
            </s.ButtonLink>
            .
          </>
        )}
      </div>
      <s.Image src={EmailCredentialImg} alt="email-credential-image" />
      <div style={{ color: "red" }}>
        <div style={{ fontWeight: "bold" }}>IMPORTANT :</div>
        This login detail (or device license) can only be used for the device
        which IMEI and License code registered on the activation process. You
        will not be able to use the login detail in other devices.
      </div>
      <s.CheckPoints>
        <div>
          <input type="checkbox" id="email-check" />
          <label htmlFor="email-check" style={{ marginLeft: "12px" }}>
            I have received the email with subject “
            <strong>Your VoicePing Device License is activated</strong>”
          </label>
        </div>
      </s.CheckPoints>
    </GuideLayout>
  );
}

export default StepView;
