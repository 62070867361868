import { useContext } from "react";
import styled from "styled-components";
import GuideLayout from "../../components/GuideLayout";

import activationType from "../../constants/activationType.json";

import { GuideContext } from "../../pages/guide";
import { goNext, goBack, closeGuide } from "../../reducers/guide";
import config from "../../config";

import * as s from "./StyledComponents";

const { showGuidedFormOnly } = config;

const Header = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`;

const TitleText = styled.div`
  font-weight: bold;
`;

const Body = styled.div`
  width: 100%;
  color: black;
  text-align: justify;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.8;
  margin: 12px 0 40px 0;

  > * {
    width: 100%;
    margin-bottom: 32px;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 500px) {
    font-size: 16px;
    line-height: 2;
  }
`;

const VIEW_TITLE = "Completion step";

function NewCompanyCompletion() {
  return (
    <>
      <s.CheckPoints>
        <div>
          <input type="checkbox" id="email-check" />
          <label htmlFor="email-check">Created my own VoicePing company</label>
        </div>
        <div>
          <input type="checkbox" id="email-check" />
          <label htmlFor="email-check">Login my first VP Teams device</label>
        </div>
      </s.CheckPoints>
    </>
  );
}

function JoinCompanyCompletion() {
  return (
    <>
      <s.CheckPoints>
        <div>
          <input type="checkbox" id="email-check" />
          <label htmlFor="email-check">
            Added another VP Teams device to my existing company
          </label>
        </div>
      </s.CheckPoints>
    </>
  );
}

function GuideWelcome({ id, isLastStep }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickBack = () => goBack(dispatch);
  const handleClickNext = () => goNext(dispatch);
  const handleCloseGuide = () => closeGuide(dispatch);
  const handleReplayGuide = () => window.location.reload();

  let CompletionBody = NewCompanyCompletion;
  if (state.activationType === activationType.JOIN_COMPANY) {
    CompletionBody = JoinCompanyCompletion;
  }

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      isLastStep={isLastStep}
      shouldReplay={showGuidedFormOnly}
      showHeader={false}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
      onCloseGuide={handleCloseGuide}
      onReplayGuide={handleReplayGuide}
    >
      <Header>
        <TitleText>{VIEW_TITLE}</TitleText>
      </Header>
      <Body>
        <CompletionBody />
      </Body>
    </GuideLayout>
  );
}

export default GuideWelcome;
