import { useContext } from "react";

import GuideLayout from "../../components/GuideLayout";
import LicenseCodeImage from "../../images/license_code.png";

import { GuideContext } from "../../pages/guide";
import {
  goNext,
  goBack,
  updateIMEI,
  updateLicenseCode,
} from "../../reducers/guide";

const VIEW_TITLE = "Find your License Code";

function StepView({ id, isFirstStep }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickNext = () => goNext(dispatch);
  const handleClickBack = () => goBack(dispatch);
  const handleUpdateIMEI = ({ target }) => updateIMEI(target.value, dispatch);
  const handleUpdateLicenseCode = ({ target }) =>
    updateLicenseCode(target.value, dispatch);

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      showBack={!isFirstStep}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <div>
        You can find <strong>IMEI</strong> and <strong>License code</strong> on
        user manual paper inside the device box. It looks like below.
      </div>
      <img
        alt="license-code-sticker-img"
        src={LicenseCodeImage}
        style={{ width: "80%" }}
      />
      <input
        placeholder="Type IMEI here"
        onBlur={handleUpdateIMEI}
        defaultValue={state.imei}
      />
      <input
        placeholder="Type license code here"
        onBlur={handleUpdateLicenseCode}
        defaultValue={state.licenseCode}
      />
    </GuideLayout>
  );
}

export default StepView;
