import React, { useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GuideUpdateApp from "../containers/GuideUpdateApp";
import GuideIMEIAndLicenseCode from "../containers/GuideIMEIAndLicenseCode";
import GuideEmailInput from "../containers/GuideEmailInput";
import GuideActivationType from "../containers/GuideActivationType";
import GuideCheckEmail from "../containers/GuideCheckEmail";
import GuideLogin from "../containers/GuideLogin";
import GuideSmartphoneTest1 from "../containers/GuideSmartphoneTest1";
import GuideSmartphoneTest2 from "../containers/GuideSmartphoneTest2";
import GuideSmartphoneTest3 from "../containers/GuideSmartphoneTest3";
import GuideCompanyNameAndJoinPIN from "../containers/GuideCompanyNameAndJoinPIN";
import GuideCompletion from "../containers/GuideCompletion";

import activationType from "../constants/activationType.json";

import {
  reducer,
  initialState,
  openGuide,
  restoreState,
} from "../reducers/guide";

export const GuideContext = React.createContext(null);

const createCompanyViews = [
  GuideActivationType,
  GuideIMEIAndLicenseCode,
  GuideEmailInput,
  GuideCheckEmail,
  GuideUpdateApp,
  GuideLogin,
  GuideCompletion,
];

const joinCompanyViews = [
  GuideActivationType,
  GuideCompanyNameAndJoinPIN,
  GuideIMEIAndLicenseCode,
  GuideEmailInput,
  GuideCheckEmail,
  GuideUpdateApp,
  GuideLogin,
  GuideCompletion,
];

const smartphoneTestViews = [
  GuideActivationType,
  GuideSmartphoneTest1,
  GuideSmartphoneTest2,
  GuideSmartphoneTest3,
];

function GuidePage() {
  const forwardProps = useReducer(reducer, initialState);
  const [state, dispatch] = forwardProps;
  const { step, isCloseGuide } = state;
  const isFirstStep = step === 0;
  let isLastStep = step >= state.totalSteps - 1;

  const navigate = useNavigate();

  let views = createCompanyViews;

  if (state.activationType === activationType.JOIN_COMPANY) {
    views = joinCompanyViews;
  } else if (state.activationType === activationType.SMARTPHONE_TEST) {
    views = smartphoneTestViews;
  }

  const StepView = views[step];

  useEffect(() => {
    if (isCloseGuide) {
      navigate("/");
    }
  }, [isCloseGuide, navigate]);

  useEffect(() => {
    if (window?.vp_dla_guide_reducer_state?.isPauseGuide) {
      const prevState = window.vp_dla_guide_reducer_state;
      restoreState(dispatch, prevState);

      let stepNumber = 2;
      if (prevState.activationType === activationType.JOIN_COMPANY) {
        stepNumber = 3;
      }

      if (window.vp_dla_uname && window.vp_dla_pass) {
        // sucessful submission indicated by these variables existence
        stepNumber = 3;
        if (prevState.activationType === activationType.JOIN_COMPANY) {
          stepNumber = 4;
        }
      }

      openGuide(dispatch, stepNumber);
    }
  }, [dispatch]);

  return (
    <GuideContext.Provider value={forwardProps}>
      <StepView
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        id={`guide-${step}`}
      />
    </GuideContext.Provider>
  );
}

export default GuidePage;
