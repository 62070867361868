import { useContext, useEffect } from "react";
import queryString from "query-string";

import GuideLayout from "../../components/GuideLayout";
import InviteFriendsImage from "../../images/invite_friends.png";

import { GuideContext } from "../../pages/guide";
import {
  goNext,
  goBack,
  updateCompany,
  updateTeamLicenseCode,
} from "../../reducers/guide";

import * as s from "./StyledComponents";

const VIEW_TITLE = "Fill company name and join PIN";

function StepView({ id }) {
  const [state, dispatch] = useContext(GuideContext);

  useEffect(() => {
    const { c, p } = queryString.parse(window.location.search);

    if (state.company && state.teamLicenseCode && c && p) {
      goNext(dispatch);
      return null;
    }
    // eslint-disable-next-line
  }, []);

  const handleClickNext = () => {
    goNext(dispatch);
  };

  const handleClickBack = () => goBack(dispatch);

  const handleUpdateCompany = ({ target }) =>
    updateCompany(target.value, dispatch);
  const handleUpdateJoinPIN = ({ target }) =>
    updateTeamLicenseCode(target.value, dispatch);

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <s.Form>
        <div>Enter the Company Name and Join PIN</div>
        <input
          placeholder="Type company name here"
          onBlur={handleUpdateCompany}
          defaultValue={state.company}
        />
        <input
          placeholder="Type join PIN here"
          onBlur={handleUpdateJoinPIN}
          defaultValue={state.teamLicenseCode}
        />
      </s.Form>
      <div>
        <strong>Where to find these?</strong> <br />
        Company and Join PIN can be found in Invite Friends screen on any{" "}
        <strong>activated</strong> VoicePing Teams device.
      </div>
      <s.Image src={InviteFriendsImage} alt="invite-friends-guide-image" />
    </GuideLayout>
  );
}

export default StepView;
