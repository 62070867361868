// DLA docs: https://docs.google.com/document/d/1KrZladI3cb4pNl3wGCEvrDVkuo0EG8yJIIBDR6MJBBY

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { version } from "../package.json";

console.log("DLA form version " + version);

// redirect from http to https in remote instances
if (
  window.location.origin.indexOf("localhost") < 0 &&
  window.location.origin.indexOf("https") < 0
) {
  window.location = window.location.origin.replace("http", "https");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
