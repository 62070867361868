import React from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const Container = styled.div`
  color: #232129;
  padding: 24px;
  font-family: -apple-system, Roboto, sans-serif, serif;
  font-size: medium;
  text-align: center;

  > * {
    margin-top: 24px;
  }
`;

const ButtonLink = styled.span`
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
`;

const SuccessPage = () => {
  const { e: email } = queryString.parse(window.location.search);

  function handleResendEmail() {
    if (!window.vp_dla_uname || !window.vp_dla_pass) {
      return;
    }

    const body = {
      email,
      username: window.vp_dla_uname,
      password: window.vp_dla_pass,
    };

    axios.post(serverUrl + "/resend-email", body);
  }

  const isPausingGuide = window?.vp_dla_guide_reducer_state?.isPauseGuide;

  return (
    <Container>
      <div>
        VoicePing account detail has been sent to{" "}
        <strong>{email || "your email"}</strong>
      </div>
      <div>
        If you don't receive the email on your inbox, please check on promotion
        or spam folder.{" "}
        {window.vp_dla_uname && window.vp_dla_pass && (
          <>
            If it's neither there, please{" "}
            <ButtonLink onClick={handleResendEmail}>
              click here to re-send the email
            </ButtonLink>
          </>
        )}
      </div>
      <div>
        If you input wrong email, please report the problem to{" "}
        <a href="mailto:sales@smartwalkietalkie.com">
          sales@smartwalkietalkie.com
        </a>
      </div>
      <div style={{ marginTop: "64px" }}>
        {!isPausingGuide && <Link to="/">Go back to form</Link>}
        {isPausingGuide && <Link to="/guide">Continue reading guide</Link>}
      </div>
    </Container>
  );
};

export default SuccessPage;
