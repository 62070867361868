import { useContext, useEffect } from "react";
import queryString from "query-string";

import GuideLayout from "../../components/GuideLayout";

import {
  NEW_COMPANY,
  JOIN_COMPANY,
  SMARTPHONE_TEST,
} from "../../constants/activationType.json";
import { GuideContext } from "../../pages/guide";
import {
  goNext,
  goBack,
  updateActivationType,
  updateCompany,
  updateTeamLicenseCode,
} from "../../reducers/guide";

import * as s from "./StyledComponents";

const VIEW_TITLE = "Choose activation type";

function StepView({ id }) {
  const [state, dispatch] = useContext(GuideContext);

  useEffect(() => {
    if (window.location.search !== "") {
      const { c, p } = queryString.parse(window.location.search);

      if (c) {
        updateCompany(c, dispatch);
      }

      if (p) {
        updateTeamLicenseCode(p, dispatch);
      }

      if (c && p) {
        goNext(dispatch);
      }
    }
  }, [dispatch]);

  const handleClickNext = () => {
    goNext(dispatch);
  };

  const handleClickBack = () => goBack(dispatch);
  const handleUpdateActivationType = ({ target }) => {
    updateActivationType(target.value, dispatch);
  };

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
      showBack={false}
    >
      <div>
        <label htmlFor="activation_type">
          Which device do you want to activate?
        </label>
      </div>
      <s.ActivationTypeOptions>
        <div>
          <input
            type="radio"
            id={NEW_COMPANY}
            name="activationType"
            value={NEW_COMPANY}
            checked={state.activationType === NEW_COMPANY}
            onChange={handleUpdateActivationType}
          />
          <label htmlFor={NEW_COMPANY}>
            I want to register my <strong>first</strong> VoicePing Teams device
          </label>
        </div>
        <div>
          <input
            type="radio"
            id={SMARTPHONE_TEST}
            name="activationType"
            value={SMARTPHONE_TEST}
            checked={state.activationType === SMARTPHONE_TEST}
            onChange={handleUpdateActivationType}
          />
          <label htmlFor={SMARTPHONE_TEST}>
            I want to login on my <strong>smartphone</strong> using the test
            account
          </label>
        </div>
        <div>
          <input
            type="radio"
            id={JOIN_COMPANY}
            name="activationType"
            value={JOIN_COMPANY}
            checked={state.activationType === JOIN_COMPANY}
            onChange={handleUpdateActivationType}
          />
          <label htmlFor={JOIN_COMPANY}>
            I want to register <strong>another</strong> VoicePing Teams device
          </label>
        </div>
      </s.ActivationTypeOptions>
    </GuideLayout>
  );
}

export default StepView;
