import { useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";

const Container = styled.div`
  max-width: 500px;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
  color: darkRed;

  input {
    padding: 0px 8px;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  font-size: 18px;
  align-items: center;
  margin-bottom: 24px;
`;

const NumberCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 18px;
  background: darkRed;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const TitleText = styled.div`
  font-weight: bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const Body = styled.div`
  width: 100%;
  color: black;
  text-align: justify;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.8;
  margin: 12px 0 32px 0;

  > * {
    width: 100%;
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 500px) {
    font-size: 16px;
    line-height: 2;
  }
`;

const Footer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const ButtonBack = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: white;
  border: none;
  outline: none;
  font-weight: bold;

  svg {
    font-size: 32px;
  }
`;

const BlankSpace = styled.div`
  flex: 1;
`;

const ButtonNext = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: white;
  border: none;
  outline: none;
  font-weight: bold;

  svg {
    font-size: 32px;
  }
`;

function GuideLayout({
  id,
  showHeader = true,
  showBack = true,
  showNext = true,
  step,
  title,
  isLastStep,
  shouldReplay,
  children,
  onClickNext,
  onClickBack,
  onCloseGuide,
  onReplayGuide,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <Container id={id}>
      {showHeader && (
        <Header>
          <NumberCircle>{step + 1}</NumberCircle>
          <TitleText>{title}</TitleText>
        </Header>
      )}
      <Body>{children}</Body>
      <Footer>
        {showBack && (
          <ButtonBack onClick={onClickBack}>
            <IoIosArrowBack />
            Back
          </ButtonBack>
        )}
        <BlankSpace />
        {showNext && (
          <ButtonNext
            onClick={
              !isLastStep
                ? onClickNext
                : !shouldReplay
                ? onCloseGuide
                : onReplayGuide
            }
          >
            {!isLastStep
              ? "Next"
              : !shouldReplay
              ? "Close guide"
              : "Back to home"}
            <IoIosArrowForward />
          </ButtonNext>
        )}
      </Footer>
    </Container>
  );
}

export default GuideLayout;
