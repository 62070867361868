import { useContext } from "react";
import styled from "styled-components";

import GuideLayout from "../../components/GuideLayout";
import VoicePingApp from "../../images/vp_install.png";

import { GuideContext } from "../../pages/guide";
import { goNext, goBack, closeGuide } from "../../reducers/guide";

const VIEW_TITLE = "Download VoicePing app on your smartphone";

const Image = styled.img`
  border-radius: 12px;
  border: 2px solid grey;
  padding: 4px;
  margin-bottom: 24px;
  width: 100%;
`;

function StepView({ id, isLastStep }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickNext = () => goNext(dispatch);
  const handleClickBack = () => goBack(dispatch);
  const handleCloseGuide = () => closeGuide(dispatch);

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      isLastStep={isLastStep}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
      onCloseGuide={handleCloseGuide}
    >
      <div>
        <strong>On your smartphone</strong>, go to Play Store and download
        VoicePing app.
      </div>
      <Image src={VoicePingApp} alt="test-user-login-detail" />
    </GuideLayout>
  );
}

export default StepView;
