import React from 'react'
import Tooltip from 'antd/es/tooltip'
import { BsFillQuestionCircleFill } from 'react-icons/bs'

const Hint = ({ text, size }) => {
  return (
    <Tooltip placement='right' title={text}>
      <BsFillQuestionCircleFill style={{ fontSize: size, color: 'lightGrey' }} />
    </Tooltip>
  )
}

export default Hint
