import { useContext } from "react";

import GuideLayout from "../../components/GuideLayout";
import VoicePingLogin1 from "../../images/login_smartphone_1.png";
import VoicePingLogin2 from "../../images/login_smartphone_2.png";
import VPFocusScreen from "../../images/vp_focus_screen_smartphone.png";

import { GuideContext } from "../../pages/guide";
import { goNext, goBack, closeGuide } from "../../reducers/guide";

import * as s from "./StyledComponents";
import config from "../../config";

const VIEW_TITLE = "Login with the test account";
const { showGuidedFormOnly } = config;

function StepView({ id, isLastStep }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickBack = () => goBack(dispatch);
  const handleClickNext = () => goNext(dispatch);
  const handleCloseGuide = () => closeGuide(dispatch);
  const handleReplayGuide = () => window.location.reload();

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      isLastStep={isLastStep}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
      onCloseGuide={handleCloseGuide}
      onReplayGuide={handleReplayGuide}
      shouldReplay={showGuidedFormOnly}
    >
      <div>
        <strong>On your smartphone</strong>, open the VoicePing app and click
        the Login button.
      </div>
      <s.Image src={VoicePingLogin1} alt="voiceping-app-login-1" />
      <div>
        Then you can use the test account login detail on the email. Make sure
        the <strong>username is 10</strong>. Press Login after filling in
        Username, Company and Password.
      </div>
      <s.Image src={VoicePingLogin2} alt="voiceping-app-login-2" />
      <s.Image src={VPFocusScreen} alt="voiceping-app-focus-channel-screen" />

      <s.CheckPoints>
        <div>
          <input type="checkbox" id="email-check" />
          <label htmlFor="email-check">
            I can see the screen as shown above after logging in
          </label>
        </div>
      </s.CheckPoints>
    </GuideLayout>
  );
}

export default StepView;
