/** NOTE:
 *  to prefill company name and join PIN, the url should follow below pattern:
 *  (the source of url is from android app later)
 *  {baseURL}?c={companyName}&p={joinPIN}
 *  e.g. https://act.voiceping.info?c=41234&p=123456
 */

import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Formik, ErrorMessage, Field, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Button from "antd/es/button";
import Spinner from "antd/es/spin";
import axios from "axios";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";

import Label from "../components/Label";
import LicenseCodeImage from "../images/license_code.png";
import InviteFriendsImage from "../images/invite_friends.png";

import { JOIN_COMPANY } from "../constants/activationType.json";
import config from "../config";

const serverUrl = process.env.REACT_APP_SERVER_URL;
const { showGuidedFormOnly } = config;

// styles
const pageStyles = {
  color: "#232129",
  padding: 24,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 24,
  maxWidth: 640,
  fontWeight: "bold",
};

const fieldStyle = {
  padding: "4px 8px",
  marginTop: 8,
  boxSizing: "border-box",
  margin: 0,
  fontVariant: "tabular-nums",
  listStyle: "none",
  fontFeatureSettings: "tnum",
  position: "relative",
  display: "inline-block",
  width: "100%",
  minWidth: 0,
  color: "rgba(0, 0, 0, 0.85)",
  fontSize: "14px",
  lineHeight: 1.5715,
  backgroundColor: "#fff",
  backgroundImage: "none",
  border: "1px solid #d9d9d9",
  borderRadius: "2px",
  transition: "all 0.3s",
};

const errorMessageStyle = {
  color: "red",
  fontSize: 12,
};

const WaitingScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background: darkRed;
  position: sticky;
  top: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 16px;
`;

// markup
const IndexPage = () => {
  const navigate = useNavigate();
  const [email2, setEmail2] = useState(
    window?.vp_dla_guide_reducer_state?.email || ""
  );

  let { c, p } = queryString.parse(window.location.search);
  let prefillImei = "";
  let prefillLicenseCode = "";
  let prefillEmail = email2;
  let prefillConfirmationEmail = "";
  let perfillActivationType = JOIN_COMPANY;
  let prefillCompanyName = c;
  let prefillJoinPIN = p;
  const [autoSubmitForm, setAutoSubmitForm] = useState(false);

  useEffect(() => {
    scrollToBottom();

    if (!window.vp_dla_skip_guide) {
      const url = window.location.search
        ? "/guide" + window.location.search
        : "/guide";

      if (showGuidedFormOnly) {
        window.location = window.location.origin + url;
      } else {
        import("antd/es/modal").then(({ default: Modal }) => {
          Modal.confirm({
            content: (
              <div style={{ margin: "24px 0" }}>
                If this is your first time here, we recommend you to fill up the
                form with guide
              </div>
            ),
            title: "Fill form with guide",
            okText: "Open guided form",
            cancelText: "Close",
            onOk() {
              window.location = window.location.origin + url;
            },
          });
        });
      }
    }

    if (
      window.vp_dla_guide_reducer_state &&
      Object.keys(window.vp_dla_guide_reducer_state).length > 0
    ) {
      const { shouldAutoSubmitForm } = window.vp_dla_guide_reducer_state;
      if (shouldAutoSubmitForm != null) {
        setAutoSubmitForm(shouldAutoSubmitForm);
      }
    }
  }, []);

  if (
    window.vp_dla_guide_reducer_state &&
    Object.keys(window.vp_dla_guide_reducer_state).length > 0
  ) {
    const {
      imei,
      licenseCode,
      email,
      confirmationEmail,
      activationType,
      company,
      teamLicenseCode,
    } = window.vp_dla_guide_reducer_state;

    prefillImei = imei;
    prefillLicenseCode = licenseCode;
    perfillActivationType = activationType;

    if (prefillEmail === "" && email !== "") {
      prefillEmail = email;
    }

    if (company !== "") {
      prefillCompanyName = company;
    }

    if (teamLicenseCode !== "") {
      prefillJoinPIN = teamLicenseCode;
    }

    if (confirmationEmail !== "") {
      prefillConfirmationEmail = confirmationEmail;
    }
  }

  const RefFormik = useRef(null);
  const RefConfirmationEmail = useRef(null);

  const [errorText, setErrorText] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const ActivationSchema = Yup.object().shape({
    imei: Yup.string()
      .matches(/^\d{15}$/, "IMEI should be 15 digits number")
      .required("IMEI is required"),
    own_license_code: Yup.string()
      .matches(/^\d{6}$/, "License code should be 6 digits number")
      .required("License code is required"),
    email: Yup.string().email("Invalid email"),
    confirm_email: Yup.string()
      .oneOf(
        [email2],
        "Please check both email addresses are entered correctly"
      )
      .email("Invalid email")
      .required("Confirmation email is required"),
    activation_type: Yup.string().required("Activation type is required"),
    company_name: Yup.string().matches(
      /^4\d{4}(\.vp)*$/,
      "Invalid company name. Example of valid company name: 41234 or 41234.vp"
    ),
    team_license_code: Yup.string().matches(
      /^\d{6}$/,
      "Join PIN should be 6 digits number"
    ),
  });

  function scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  function handleSubmit(values) {
    setShowSpinner(true);
    setErrorText("");

    if (values.activation_type === "join_company") {
      if (
        (!values.company_name || values.company_name === "") &&
        (!values.team_license_code || values.team_license_code === "")
      ) {
        setErrorText("Please fill Company name and Join PIN fields");
        setShowSpinner(false);
        return;
      } else if (!values.company_name || values.company_name === "") {
        setErrorText("Please fill in Company name field");
        setShowSpinner(false);
        return;
      } else if (!values.team_license_code || values.team_license_code === "") {
        setErrorText("Please fill in Join PIN field");
        setShowSpinner(false);
        return;
      }

      const data = {
        imei: values.imei,
        licenseCode: values.own_license_code,
        company: values.company_name,
        teamLicenseCode: values.team_license_code,
        email: values.confirm_email,
      };

      axios
        .post(serverUrl + "/join-company", data)
        .then((res) => {
          const { username, password } = res.data;
          window.vp_dla_uname = username;
          window.vp_dla_pass = password;

          // updating reducer state to have updated data on form
          window.vp_dla_guide_reducer_state = {
            ...window.vp_dla_guide_reducer_state,
            imei: values.imei,
            licenseCode: values.own_license_code,
            email: values.confirm_email,
          };

          if (showGuidedFormOnly) {
            navigate("/guide");
          } else {
            navigate(`/success?e=${values.confirm_email}`);
          }

          setShowSpinner(false);
        })
        .catch((error) => {
          const { errorCode } = error.response.data;
          if (errorCode === 2) {
            setErrorText("Wrong IMEI or License code, please check again.");
          } else if (errorCode === 121) {
            setErrorText("Wrong company name or join PIN, please check again.");
          } else if (errorCode === 102) {
            setErrorText(
              "IMEI has been registered before, please check if you input the right IMEI."
            );
          } else if (errorCode === 125) {
            setErrorText("Email is already taken, please try another email.");
          } else if (errorCode === 1) {
            setErrorText(
              "Invalid company name. Example of valid company name: 41234 or 41234.vp."
            );
          } else if (errorCode === 6) {
            setErrorText("Invalid join PIN. It should be 6 digits number");
          } else {
            setErrorText(
              "Registration failed, please contact VoicePing at sales@smartwalkietalkie.com. Error code: " +
                errorCode
            );
          }
          setShowSpinner(false);
          scrollToBottom();
        });
    } else if (values.activation_type === "new_company") {
      const data = {
        imei: values.imei,
        licenseCode: values.own_license_code,
        email: values.confirm_email,
      };

      axios
        .post(serverUrl + "/new-company", data)
        .then((res) => {
          const { username, password } = res.data;
          window.vp_dla_uname = username;
          window.vp_dla_pass = password;

          // updating reducer state to have updated data on form
          window.vp_dla_guide_reducer_state = {
            ...window.vp_dla_guide_reducer_state,
            imei: values.imei,
            licenseCode: values.own_license_code,
            email: values.confirm_email,
          };

          if (showGuidedFormOnly) {
            navigate("/guide");
          } else {
            navigate(`/success?e=${values.confirm_email}`);
          }

          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);

          const { errorCode } = error.response.data;
          if (errorCode === 2) {
            setErrorText("Wrong IMEI or License code, please check again.");
          } else if (errorCode === 102) {
            setErrorText(
              "IMEI has been registered before, please check if you input the right IMEI."
            );
          } else if (errorCode === 125) {
            setErrorText("Email is already taken, please try another email.");
          } else {
            setErrorText(
              "Registration failed, please contact VoicePing at sales@smartwalkietalkie.com. Error code: " +
                errorCode
            );
          }

          scrollToBottom();
        });
    }
  }

  useEffect(() => {
    if (RefFormik.current && prefillConfirmationEmail) {
      RefFormik.current.setFieldValue(
        "confirm_email",
        prefillConfirmationEmail
      );

      if (RefConfirmationEmail) {
        RefConfirmationEmail.current.focus();
        RefConfirmationEmail.current.blur();

        if (autoSubmitForm) {
          RefFormik.current.submitForm();
        }
      }
    }
  }, [autoSubmitForm, prefillConfirmationEmail, RefConfirmationEmail]);

  if (showGuidedFormOnly && !window.vp_dla_guide_reducer_state?.isPauseGuide) {
    return null;
  }

  return (
    <>
      {showSpinner && showGuidedFormOnly && (
        <WaitingScreen>Activating the license, please wait ...</WaitingScreen>
      )}
      <main style={pageStyles}>
        <div>
          <title>VoicePing Device License Activation</title>
          <h1 style={headingStyles}>VoicePing Device License Activation</h1>
          <Formik
            initialValues={{
              imei: prefillImei,
              own_license_code: prefillLicenseCode,
              email: prefillEmail,
              confirm_email: prefillConfirmationEmail,
              activation_type: perfillActivationType,
              company_name: prefillCompanyName,
              team_license_code: prefillJoinPIN,
            }}
            innerRef={RefFormik}
            validationSchema={ActivationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, setValues }) => {
              const disableButton =
                showSpinner || Object.keys(errors).length > 0;
              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    maxWidth: "500px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      textAlign: "justify",
                      marginBottom: "24px",
                      lineHeight: "1.5",
                    }}
                  >
                    Please fill and submit form below to activate your license.
                    To get hint, touch question mark icons or read{" "}
                    <a
                      href="https://www.voicepingapp.com/blog/device-license-activation-guide"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      full guide here
                    </a>
                    .
                  </div>
                  <Label
                    labelText="IMEI"
                    hint="IMEI is 15 digits number. Find it in your user manual"
                  />
                  <Field id="imei" name="imei" type="text" style={fieldStyle} />
                  <ErrorMessage
                    component="div"
                    name="imei"
                    style={errorMessageStyle}
                  />
                  <br />
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: 0,
                      color: "#6C6C6C",
                    }}
                  >
                    Find sticker like below on user manual
                  </p>
                  <img
                    alt="license-code-sticker-img"
                    src={LicenseCodeImage}
                    width="160px"
                    style={{ marginBottom: 8 }}
                  />
                  <br />
                  <Label
                    labelText="License code"
                    hint="License code is 6 digit numbers. Find it in your user manual"
                  />
                  <Field
                    id="own_license_code"
                    name="own_license_code"
                    type="text"
                    style={fieldStyle}
                  />
                  <ErrorMessage
                    component="div"
                    name="own_license_code"
                    style={errorMessageStyle}
                  />
                  <br />
                  <Label
                    labelText="Email"
                    hint="Your valid email address. The account credential will be sent to this email upon submission"
                  />
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    style={fieldStyle}
                    defaultValue={prefillEmail}
                    onBlur={({ target }) => {
                      setEmail2(target.value);
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name="email"
                    style={errorMessageStyle}
                  />
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: 0,
                      color: "#6C6C6C",
                    }}
                  >
                    The account credential will be sent to your email upon form
                    submission.
                    <br />
                    Then you can login on VoicePing app with the credential.
                  </p>
                  <br />
                  <Label
                    labelText="Confirmation email"
                    hint="Type again your email, make sure you input the right email"
                  />
                  <Field
                    innerRef={RefConfirmationEmail}
                    id="confirm_email"
                    name="confirm_email"
                    type="email"
                    style={fieldStyle}
                    value={values.confirm_email}
                    onPaste={() => {
                      setTimeout(() => {
                        setValues({ ...values, confirm_email: "" });
                      });
                      import("antd/es/modal").then(({ default: Modal }) => {
                        Modal.warn({
                          content:
                            "You should not paste to confirmation email input. You have to type to make sure you input the correct email.",
                          title: "Paste unallowed",
                          onOk() {
                            RefConfirmationEmail.current.focus();
                          },
                        });
                      });
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name="confirm_email"
                    style={errorMessageStyle}
                  />
                  <br />
                  <Label
                    labelText="I want to use my license to:"
                    hint="Select 'Join an existing company' to join your friends in existing company or select 'Create a new company' if you are the first one"
                  />
                  <label style={{ width: "max-content" }}>
                    <Field
                      name="activation_type"
                      type="radio"
                      value="join_company"
                      onChange={() => {
                        setErrorText("");
                        setValues({
                          ...values,
                          activation_type: "join_company",
                          company_name: window.vp_dla_company_name || "",
                          team_license_code:
                            window.vp_dla_team_license_code || "",
                        });
                      }}
                    />
                    &nbsp; Join an existing company
                  </label>
                  <label style={{ width: "max-content" }}>
                    <Field
                      name="activation_type"
                      type="radio"
                      value="new_company"
                      onChange={() => {
                        window.vp_dla_company_name = values.company_name;
                        window.vp_dla_team_license_code =
                          values.team_license_code;
                        setErrorText("");
                        setValues({
                          ...values,
                          activation_type: "new_company",
                          company_name: "",
                          team_license_code: "",
                        });
                      }}
                    />
                    &nbsp; Create a new company
                  </label>
                  <ErrorMessage
                    component="div"
                    name="activation_type"
                    style={errorMessageStyle}
                  />
                  <br />
                  {values.activation_type === "join_company" && (
                    <>
                      <Label
                        labelText="Company name"
                        hint="The company name you want to join, example: 41234 or 41234.vp. Ask your friend for this on Invite friends menu on their VoicePing app"
                      />
                      <Field
                        id="company_name"
                        name="company_name"
                        type="text"
                        style={fieldStyle}
                      />
                      <ErrorMessage
                        component="div"
                        name="company_name"
                        style={errorMessageStyle}
                      />
                      <br />
                      <Label
                        labelText="Join PIN"
                        hint="Join PIN from any member of the company you want to join. Ask your friend for this on Invite friends menu on their VoicePing app"
                      />
                      <Field
                        id="team_license_code"
                        name="team_license_code"
                        type="text"
                        style={fieldStyle}
                      />
                      <ErrorMessage
                        component="div"
                        name="team_license_code"
                        style={errorMessageStyle}
                      />
                      <br />
                      <p
                        style={{
                          textAlign: "left",
                          marginBottom: 0,
                          color: "#6C6C6C",
                        }}
                      >
                        To get Company name and Join PIN, go to Invite Friends
                        screen on any activated VoicePing Teams device.
                      </p>
                      <br />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          alt="invite-friends-guide-img"
                          src={InviteFriendsImage}
                          width="300px"
                          style={{ marginBottom: 8 }}
                        />
                      </div>
                    </>
                  )}
                  {showSpinner && (
                    <Spinner style={{ margin: "12px 12px 24px 12px" }} />
                  )}
                  {errorText && (
                    <div
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginTop: 12,
                        marginBottom: 24,
                      }}
                    >
                      {`${errorText}`}
                    </div>
                  )}
                  <Button
                    htmlType="submit"
                    style={{
                      padding: "12px",
                      height: 54,
                      background: disableButton ? "grey" : "darkRed",
                      color: "white",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                    disabled={disableButton}
                  >
                    ACTIVATE
                  </Button>
                  {errors && (
                    <>
                      <br />
                      <div style={errorMessageStyle}>
                        {Object.values(errors).join(", ")}
                      </div>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
          <div style={{ marginTop: "48px", fontSize: "small" }}>
            Should you need to contact VoicePing team, <br /> please email to{" "}
            <a href="mailto:sales@smartwalkietalkie.com?subject=VoicePing%20Device%20License%20Activation">
              sales@smartwalkietalkie.com
            </a>
          </div>
        </div>
      </main>
    </>
  );
};

export default IndexPage;
