import styled from "styled-components";

export const ActivationTypeOptions = styled.div`
  > div {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;

    > label {
      flex: 1;
      margin-left: 12px;
    }
  }
`;
