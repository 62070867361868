import React from "react";
import Hint from "../Hint";

const labelStyle = {
  fontWeight: "bold",
  fontSize: 16,
  marginRight: 8,
  display: "inline-block",
};

const Label = ({ labelText, targetId, hint }) => {
  return (
    <div>
      <label htmlFor={targetId} style={labelStyle}>
        {labelText}
      </label>
      <Hint text={hint} size="20px" />
    </div>
  );
};

export default Label;
