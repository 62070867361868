import { useContext } from "react";
import styled from "styled-components";

import GuideLayout from "../../components/GuideLayout";
import TestUserCredential from "../../images/email_test_user_credential.png";
import EmailSearchImage from "../../images/email_search.png";

import { GuideContext } from "../../pages/guide";
import { goNext, goBack, closeGuide } from "../../reducers/guide";

const VIEW_TITLE = "Open the previous email from VoicePing";

const Image = styled.img`
  border-radius: 12px;
  border: 2px solid grey;
  padding: 4px;
  margin-bottom: 24px;
  width: 100%;
`;

function StepView({ id, isLastStep }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickNext = () => goNext(dispatch);
  const handleClickBack = () => goBack(dispatch);
  const handleCloseGuide = () => closeGuide(dispatch);

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      isLastStep={isLastStep}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
      onCloseGuide={handleCloseGuide}
    >
      <div>
        If you can not spot the previous email from VoicePing easily, type on
        the search bar “<strong>VoicePing Device License</strong>” and press
        enter.
      </div>
      <Image src={EmailSearchImage} alt="search-email-from-voiceping" />
      <div>
        Open the email. Find the test account detail is the below one (with
        username 10@xxxxx.vp).
      </div>
      <Image src={TestUserCredential} alt="test-user-login-detail" />

      <div>
        If you do not have this email, go back and complete the{" "}
        <strong>Register my first VoicePing Teams device</strong> step instead.
      </div>
    </GuideLayout>
  );
}

export default StepView;
