import styled from "styled-components";

export const CheckPoints = styled.div`
  > div {
    display: flex;
    align-items: baseline;

    > label {
      flex: 1;
      margin-left: 8px;
    }
  }
`;
