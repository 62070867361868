import { useContext, useRef } from "react";
import GuideLayout from "../../components/GuideLayout";

import { GuideContext } from "../../pages/guide";
import {
  goBack,
  pauseGuide,
  updateEmail,
  updateConfirmationEmail,
  setAutoSubmitForm,
} from "../../reducers/guide";

const VIEW_TITLE = "Your Email Address for Login Credentials";

function StepView({ id }) {
  const RefConfirmationEmail = useRef(null);
  const [state, dispatch] = useContext(GuideContext);

  const handleClickNext = () => {
    setAutoSubmitForm(dispatch, true);
    pauseGuide(dispatch);
  };
  const handleClickBack = () => goBack(dispatch);
  const handleUpdateEmail = ({ target }) => updateEmail(target.value, dispatch);
  const handleUpdateConfirmationEmail = ({ target }) =>
    updateConfirmationEmail(target.value, dispatch);

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <input
        placeholder="Type your email here"
        onBlur={handleUpdateEmail}
        defaultValue={state.email}
      />
      <input
        ref={RefConfirmationEmail}
        placeholder="Type your email again here"
        onBlur={handleUpdateConfirmationEmail}
        defaultValue={state.confirmationEmail}
        onPaste={() => {
          setTimeout(() => {
            // RefConfirmationEmail.currentTarget.value = "";
            console.log("this: ", this);
            console.log("RefConfirmationEmail: ", RefConfirmationEmail);
            RefConfirmationEmail.current.value = "";
            handleUpdateConfirmationEmail({ target: { value: "" } });
          });
          import("antd/es/modal").then(({ default: Modal }) => {
            Modal.warn({
              content:
                "You should not paste to confirmation email input. You have to type to make sure you input the correct email.",
              title: "Paste unallowed",
              onOk() {
                RefConfirmationEmail.current.focus();
              },
            });
          });
        }}
      />
      <div>
        A confirmation email with the login information will be{" "}
        <strong>sent to this email</strong> address after you submit the form.
      </div>
    </GuideLayout>
  );
}

export default StepView;
