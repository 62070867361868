import { useContext } from "react";

import GuideLayout from "../../components/GuideLayout";
import VoicePingLogin1 from "../../images/login_1.png";
import VoicePingLogin2 from "../../images/login_2.png";
import VPFocusScreen from "../../images/vp_focus_screen.jpg";

import { GuideContext } from "../../pages/guide";
import { goNext, goBack } from "../../reducers/guide";

import * as s from "./StyledComponents";

const VIEW_TITLE = "Login on VoicePing Teams";

function StepView({ id }) {
  const [state, dispatch] = useContext(GuideContext);

  const handleClickNext = () => goNext(dispatch);
  const handleClickBack = () => goBack(dispatch);

  return (
    <GuideLayout
      id={id}
      step={state.step}
      title={VIEW_TITLE}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <div>Open VoicePing app and press the Login button.</div>
      <s.Image src={VoicePingLogin1} alt="voiceping-app-login-1" />
      <div>
        Then you can use the login information provided in the{" "}
        <strong>email you received</strong>.
      </div>
      <div>Press Login after filling in Username, Company and Password.</div>
      <s.Image src={VoicePingLogin2} alt="voiceping-app-login-2" />
      <s.Image src={VPFocusScreen} alt="voiceping-app-focus-channel-screen" />

      <s.CheckPoints>
        <div>
          <input type="checkbox" id="email-check" />
          <label htmlFor="email-check">
            I can see the screen as shown above after logging in
          </label>
        </div>
      </s.CheckPoints>
    </GuideLayout>
  );
}

export default StepView;
