import styled from "styled-components";

export const Form = styled.div`
  width: 100%;

  > * {
    margin-bottom: 12px;
    width: 100%;
  }
`;

export const Image = styled.img`
  border-radius: 12px;
  border: 2px solid grey;
  padding: 16px;
  margin-bottom: 24px;
  width: 90%;
`;
