import activationType from "../constants/activationType.json";

const NEXT = "guide/NEXT";
const BACK = "guide/BACK";
const CLOSE = "guide/CLOSE";
const OPEN = "guide/OPEN";
const PAUSE = "guide/PAUSE";
const UPDATE_IMEI = "guide/UPDATE_IMEI";
const UPDATE_LICENSE_CODE = "guide/UPDATE_LICENSE_CODE";
const UPDATE_EMAIL = "guide/UPDATE_EMAIL";
const UPDATE_CONFIRMATION_EMAIL = "guide/UPDATE_CONFIRMATION_EMAIL";
const UPDATE_ACTIVATION_TYPE = "guide/UPDATE_ACTIVATION_TYPE";
const UPDATE_COMPANY = "guide/UPDATE_COMPANY";
const UPDATE_TEAM_LICENSE_CODE = "guide/UPDATE_TEAM_LICENSE_CODE";
const RESTORE_STATE = "guide/RESTORE_STATE";
const AUTO_SUBMIT_FORM = "guide/AUTO_SUBMIT_FORM";
const SET_TOTAL_STEPS = "guide/SET_TOTAL_STEPS";

export function goNext(dispatch) {
  dispatch({ type: NEXT });
}

export function goBack(dispatch) {
  dispatch({ type: BACK });
}

export function openGuide(dispatch, step) {
  dispatch({ type: OPEN, step });
}

export function closeGuide(dispatch) {
  dispatch({ type: CLOSE });
  window.vp_dla_skip_guide = true;
}

export function pauseGuide(dispatch) {
  dispatch({ type: PAUSE });
  window.vp_dla_skip_guide = true;
  window.vp_dla_guide_reducer_dispatch = dispatch;
}

export function updateIMEI(value, dispatch) {
  dispatch({ type: UPDATE_IMEI, value });
}

export function updateLicenseCode(value, dispatch) {
  dispatch({ type: UPDATE_LICENSE_CODE, value });
}

export function updateEmail(value, dispatch) {
  dispatch({ type: UPDATE_EMAIL, value });
}

export function updateConfirmationEmail(value, dispatch) {
  dispatch({ type: UPDATE_CONFIRMATION_EMAIL, value });
}

export function updateActivationType(value, dispatch) {
  dispatch({ type: UPDATE_ACTIVATION_TYPE, value });

  if (value === activationType.NEW_COMPANY) {
    setTotalSteps(dispatch, 7);
  } else if (value === activationType.JOIN_COMPANY) {
    setTotalSteps(dispatch, 8);
  } else if (value === activationType.SMARTPHONE_TEST) {
    setTotalSteps(dispatch, 4);
  }
}

export function updateCompany(value, dispatch) {
  dispatch({ type: UPDATE_COMPANY, value });
}

export function updateTeamLicenseCode(value, dispatch) {
  dispatch({ type: UPDATE_TEAM_LICENSE_CODE, value });
}

export function restoreState(dispatch, state) {
  dispatch({ type: RESTORE_STATE, state });
}

export function setAutoSubmitForm(dispatch, value) {
  dispatch({ type: AUTO_SUBMIT_FORM, value });
}

export function setTotalSteps(dispatch, value) {
  dispatch({ type: SET_TOTAL_STEPS, value });
}

export const initialState = {
  totalSteps: 8,
  step: 0,
  isCloseGuide: false,
  isPauseGuide: false,
  imei: "",
  licenseCode: "",
  email: "",
  confirmationEmail: "",
  activationType: activationType.JOIN_COMPANY,
  company: "",
  teamLicenseCode: "",
  shouldAutoSubmitForm: false,
};

export function reducer(state, action) {
  const { step } = state;

  switch (action.type) {
    case NEXT:
      return {
        ...state,
        step: step < state.totalSteps - 2 ? step + 1 : state.totalSteps - 1,
      };

    case BACK:
      return {
        ...state,
        step: step > 1 ? step - 1 : 0,
      };

    case CLOSE:
      delete window.vp_dla_guide_reducer_state;
      return {
        ...state,
        isCloseGuide: true,
      };

    case PAUSE:
      const newState = {
        ...state,
        isCloseGuide: true,
        isPauseGuide: true,
      };
      window.vp_dla_guide_reducer_state = newState;
      return newState;

    case AUTO_SUBMIT_FORM:
      return {
        ...state,
        shouldAutoSubmitForm: action.value || false,
      };

    case OPEN:
      return {
        ...state,
        step: action.step,
        isCloseGuide: false,
      };

    case UPDATE_IMEI:
      return {
        ...state,
        imei: action.value,
      };

    case UPDATE_LICENSE_CODE:
      return {
        ...state,
        licenseCode: action.value,
      };

    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.value,
      };

    case UPDATE_CONFIRMATION_EMAIL:
      return {
        ...state,
        confirmationEmail: action.value,
      };

    case UPDATE_ACTIVATION_TYPE:
      return {
        ...state,
        activationType: action.value,
      };

    case UPDATE_COMPANY:
      return {
        ...state,
        company: action.value,
      };

    case UPDATE_TEAM_LICENSE_CODE:
      return {
        ...state,
        teamLicenseCode: action.value,
      };

    case RESTORE_STATE:
      return {
        ...state,
        ...action.state,
        isPauseGuide: false,
      };

    case SET_TOTAL_STEPS:
      return {
        ...state,
        totalSteps: Number(action.value),
      };

    default:
      console.error("action is not recognized");
      return state;
  }
}
